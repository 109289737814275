module Index

open Elmish
open Feliz.Router

type Page =
    | Simulator
    | About
    | NotFound

let parseUrl = function
    | [] | [ "simulador" ] -> Page.Simulator
    | _ -> Page.NotFound

type PageModel =
    | SimulatorModel of Simulator.Model
    | AboutModel of About.Model
    | NotFoundModel of NotFound.Model

type Model = {
    CurrentPage: Page
    CurrentModel: PageModel
}

type Msg =
    | UrlChanged of string list
    | SimulatorMsg of Simulator.Msg
    | AboutMsg of About.Msg
    | NotFoundMsg of NotFound.Msg

let initPage page = 
    match page with
    | Simulator ->
        let pageModel, pageCmd = Simulator.init ()
        {
            CurrentPage = page
            CurrentModel = SimulatorModel pageModel
        }, pageCmd |> Cmd.map SimulatorMsg
    | About ->
        let pageModel, pageCmd = About.init ()
        {
            CurrentPage = page
            CurrentModel = AboutModel pageModel
        }, pageCmd |> Cmd.map AboutMsg
    | NotFound ->
        let pageModel, pageCmd = NotFound.init ()
        {
            CurrentPage = page
            CurrentModel = NotFoundModel pageModel
        }, pageCmd |> Cmd.map NotFoundMsg

let init () = 
    Router.currentUrl()
    |> parseUrl
    |> initPage

let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg, model.CurrentModel with
    | UrlChanged segments, _ ->
        segments |> parseUrl |> initPage
    | AboutMsg pageMsg, AboutModel pageModel -> model, Cmd.none
    | SimulatorMsg pageMsg, SimulatorModel pageModel ->
        let simulatorModel, simulatorCmd = Simulator.update pageMsg pageModel 
        { model with CurrentModel = SimulatorModel simulatorModel }, (Cmd.map SimulatorMsg simulatorCmd)
    | NotFoundMsg pageMsg, NotFoundModel pageModel -> model, Cmd.none
    | _ -> model, Cmd.none


open Feliz
open Feliz.Bulma

let view (model: Model) (dispatch: Msg -> unit) =
    let activePage =
        match model.CurrentModel with
        | SimulatorModel pageModel -> Simulator.view pageModel (SimulatorMsg >> dispatch)
        | AboutModel pageModel -> About.view pageModel (AboutMsg >> dispatch)
        | NotFoundModel pageModel -> NotFound.view pageModel (NotFoundMsg >> dispatch)

    Html.div [
        prop.children [
            Bulma.hero [
                color.isLink
                prop.children [
                    Bulma.heroBody [
                        prop.classes ["has-text-centered"]
                        prop.children [
                            Html.p [
                                prop.className "title"
                                prop.text "Simula tu hipoteca"
                            ]
                            Html.p [
                                prop.className "subtitle"
                                prop.text "Simula tu hipoteca es una aplicación simple para calcular hipotecas con distintos parámetros"
                            ]
                        ]
                    ]
                ]
            ]
            Bulma.section [
                color.hasBackgroundLight
                prop.children [
                    React.router [
                        router.onUrlChanged (UrlChanged >> dispatch)
                        router.children [ activePage ]
                    ]
                ]
            ]
            Bulma.footer [
                color.hasBackgroundWhite
                prop.children [
                    Bulma.content [
                        prop.classes [ "has-text-centered" ]
                        prop.children [
                            Html.p [
                                prop.text "Simula-tu-hipoteca by @dagaren"
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]