module Simulator

open Elmish
open Form
open Summary
open Shared
open Introduction
open Icon
open Shared

type Model = {
    Form: Form.Model
    Summary: Summary.Model
    Table: Table.Model
    
    SelectedMortgage: Shared.FixedRateMortgage option
}

type Msg =
    | FormMsg of Form.Msg
    | SummaryMsg of Summary.Msg
    | TableMsg of Table.Msg

let init () = 
    let form, _ = Form.init ()
    let summary, _ = Summary.init ()
    let table, _ = Table.init ()
    
    let model = {
        Form = form
        Summary = summary
        Table = table
        SelectedMortgage = None
    }
    model, Cmd.none

let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg with
    | FormMsg msg ->
        match msg with 
        | Simulate mortgageData ->
            let principal = Option.get mortgageData.Principal
            let term = Option.get mortgageData.Term |> uint
            let percentage = Option.get mortgageData.Interest

            let selectedMortgage: FixedRateMortgage = Shared.FixedRateMortgage.createWithTerm principal percentage term
            let amortization = FixedRateMortgage.calculateAmortizationSchedule selectedMortgage
            
            let fullMortgage: FullMortgage = {
                Definition = selectedMortgage
                Amortization = amortization
            }

            { model with 
                SelectedMortgage = Some selectedMortgage
                Table = { model.Table with Mortgage =  Some fullMortgage }
                Summary = { Mortgage = Some fullMortgage } }, Cmd.none
        | _ ->
            let formModel, _ = Form.update msg model.Form
            { model with Form = formModel }, Cmd.none
    | SummaryMsg msg ->
        let summaryModel, _ = Summary.update msg model.Summary
        { model with Summary = summaryModel }, Cmd.none
    | TableMsg msg ->
        let tableModel, _ = Table.update msg model.Table
        { model with Table = tableModel }, Cmd.none

open Feliz
open Feliz.Bulma

let view (model: Model) (dispatch: Msg -> unit) =
    let formView = Form.view model.Form (fun msg -> dispatch (FormMsg msg))
    let summaryView = Summary.view model.Summary (fun msg -> dispatch (SummaryMsg msg))
    let tableView = Table.view model.Table (fun msg -> dispatch (TableMsg msg))

    Bulma.container [
        Bulma.columns [
            Bulma.column [
                prop.classes ["is-one-third"]
                prop.children [
                    Bulma.box [
                        formView
                    ]
                ]
                
            ]
            Bulma.column [
                if Option.isSome model.SelectedMortgage then
                    Bulma.box [
                        summaryView
                    ]
            
                    Bulma.box [
                        tableView
                    ]

                    Bulma.box [
                        Warning.view ()
                    ]
                else
                    Bulma.box [
                        Introduction.view ()
                    ]
                    Bulma.box [
                        Warning.view ()
                    ]
            ]
        ]
    ]
