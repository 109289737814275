namespace Shared

open System

type Percentage = private Percentage of decimal

module Percentage = 
    let tryCreate (value:decimal) : Result<Percentage,string> =
        if value > 1.0M then
            Error "Interest value cannot be greater than 1"
        else if value < 0.0M then
            Error "Interest value cannot be less than 0"
        else
            Ok (Percentage value)

    let tryCreateFromInt (value:decimal) : Result<Percentage, string> =
        tryCreate (value / 100M)

    let tryCreateFromFloat (value:float) : Result<Percentage,string> = 
        tryCreate ((decimal)value)

    let unsafeCreate (value:decimal) : Percentage = Percentage value
    
    let value (Percentage value) = value 

    let calculate amount percentage =
        let result = amount * (value percentage)

        Math.Round(result, 2)
    
    let calculateRemaining amount percentage =
        let percentageAmount = calculate amount percentage

        amount - percentageAmount

    let toFloat (Percentage value) = (float)value

type Amortization =  {
    Total: decimal
    Amount: decimal
    Interest: decimal
}

type FixedRateMortgage = {
    Principal: decimal
    Interest: Percentage
    MonthlyPayment: decimal
}

type FullMortgage = {
    Definition: FixedRateMortgage
    Amortization: Amortization list
}

type EarlyAmortizationConsequence = 
| ReduceTerm
| ReducePayment

type EarlyAmortization = {
    Amount: decimal
    Commission: Percentage
    Consequence: EarlyAmortizationConsequence
}

type AmortizationStep =
| MonthlyAmortizationStep of Amortization
| EarlyAmortizationStep of Amortization

type AmortizationPlan = {
    Mortgage: FixedRateMortgage
    Steps: AmortizationStep list
    EarlyAmortizations: Map<uint,EarlyAmortization>
}

module TryParser =
    // convenient, functional TryParse wrappers returning option<'a>
    let tryParseWith (tryParseFunc: string -> bool * _) = tryParseFunc >> function
        | true, v    -> Some v
        | false, _   -> None

    let parseDate    = tryParseWith System.DateTime.TryParse
    let parseInt     = tryParseWith System.Int32.TryParse
    let parseSingle  = tryParseWith System.Single.TryParse
    let parseDouble  = tryParseWith System.Double.TryParse
    let parseDecimal = tryParseWith System.Decimal.TryParse

       // active patterns for try-parsing strings
    let (|Date|_|)    = parseDate
    let (|Int|_|)     = parseInt
    let (|Single|_|)  = parseSingle
    let (|Double|_|)  = parseDouble
    let (|Decimal|_|) = parseDecimal
