namespace Shared

[<CompilationRepresentation(CompilationRepresentationFlags.ModuleSuffix)>]
module Amortization =
    let calculateEarlyAmortizationStep (earlyAmortizations:Map<uint,EarlyAmortization>) args beforeMonth =
        match args with
        | Error err -> 
            Error err
        | Ok(steps, pendingMortgage) -> 
            result {
                // Before early amortization steps
                let previousMonth =
                    earlyAmortizations
                    |> Map.keys
                    |> List.ofSeq
                    |> List.append [0u]
                    |> List.sort
                    |> List.filter (fun x -> x < beforeMonth)
                    |> List.last
                let expectedMonths = beforeMonth - previousMonth

                let term = FixedRateMortgage.getTerm pendingMortgage

                let! numMonths =
                    match expectedMonths with
                    | x when x > term ->
                        printf "Invalid number of months: %d, pending term: %d" x term
                        Error "Invalid number of months:"
                    | x -> Ok(expectedMonths)

                let mortgageSteps = 
                    FixedRateMortgage.calculateAmortizationSchedule pendingMortgage 
                    |> List.take ((int)numMonths)
                    |> List.map MonthlyAmortizationStep
                let pendingBeforeEarlyAmortization = FixedRateMortgage.getPendingPrincipalBeforeMonth pendingMortgage ((uint)numMonths)

                // Early amortization steps
                let ea = earlyAmortizations.[beforeMonth]
                let interestAmount = Percentage.calculate ea.Amount ea.Commission
                let a = {
                    Total = ea.Amount
                    Amount = ea.Amount - interestAmount
                    Interest = interestAmount
                }

                let! earlyAmortizationStep =
                    match a.Amount with
                    | x when pendingBeforeEarlyAmortization < x -> Error "Invalid amortization amount"
                    | x -> Ok (EarlyAmortizationStep a)

                // Pending mortgage after early amortization
                let newSteps = List.concat [steps; mortgageSteps; [earlyAmortizationStep]]
                let pendingPrincipalAfterEarlyAmortization = pendingBeforeEarlyAmortization - a.Amount

                let newPendingMortgage =
                    match ea.Consequence with
                    | ReduceTerm ->
                        FixedRateMortgage.createWithMonthlyPayment pendingPrincipalAfterEarlyAmortization pendingMortgage.Interest pendingMortgage.MonthlyPayment
                    | ReducePayment ->
                        let pendingTerm = term - numMonths
                        FixedRateMortgage.createWithTerm pendingPrincipalAfterEarlyAmortization pendingMortgage.Interest pendingTerm 

                return (newSteps, newPendingMortgage)
            }

    let calculateAmortizationSteps mortgage (earlyAmortizations:Map<uint,EarlyAmortization>) = 
        let result = 
            Map.keys earlyAmortizations
            |> List.ofSeq
            |> List.sort
            |> List.fold (calculateEarlyAmortizationStep earlyAmortizations) (Ok([], mortgage))

        match result with
            | Ok(steps, pendingMortgage) ->
                let pendingMortgageSteps = 
                    pendingMortgage 
                    |> FixedRateMortgage.calculateAmortizationSchedule
                    |> List.map MonthlyAmortizationStep
                Ok(List.concat [steps; pendingMortgageSteps])
            | Error err -> Error err

    let createPlan (mortgage:FixedRateMortgage) = 
        let plan = {
            Mortgage = mortgage
            EarlyAmortizations = Map.empty<uint,EarlyAmortization>
            Steps = []
        }

        let stepsResult = calculateAmortizationSteps plan.Mortgage plan.EarlyAmortizations 
        
        match stepsResult with
        | Ok steps -> 
            Ok { plan with Steps = steps }
        | Error err -> Error "Error creating amortization steps"

    let addEarlyAmortization plan earlyAmortization beforeMonth = 
        let newPlan = { plan with EarlyAmortizations = Map.add beforeMonth earlyAmortization plan.EarlyAmortizations }

        let stepsResult = calculateAmortizationSteps newPlan.Mortgage newPlan.EarlyAmortizations
        match stepsResult with
        | Ok steps -> 
            Ok { newPlan with Steps = steps }
        | Error err -> 
            printf "Error creating amortization steps: %s" err
            Error "Error creating amortization steps"

    let addEarlyAmortizationForMonths plan earlyAmortization months =
        months |> List.fold (fun result month ->
                                match result with
                                | Ok plan -> addEarlyAmortization plan earlyAmortization month
                                | Error err -> Error err) (Ok(plan))

    let print amortizationPlan =
        amortizationPlan.Steps 
        |> List.iter (fun s ->
            match s with 
            | MonthlyAmortizationStep a -> printfn "Monthly payment. Total: %M, Amount: %M, Interest: %M" a.Total a.Amount a.Interest
            | EarlyAmortizationStep a -> printfn  "Early amortization. Total: %M, Amount: %M, Commission: %M" a.Total a.Amount a.Interest)

        let total =
            amortizationPlan.Steps
            |> List.map (fun x ->
                            match x with
                            | MonthlyAmortizationStep a -> a.Total
                            | EarlyAmortizationStep a -> a.Total)
            |> List.fold (fun acc el -> acc + el) 0M


        let principal =
            amortizationPlan.Steps
            |> List.map (fun x ->
                            match x with
                            | MonthlyAmortizationStep a -> a.Amount
                            | EarlyAmortizationStep a -> a.Amount)
            |> List.fold (fun acc el -> acc + el) 0M

        let interests = 
            amortizationPlan.Steps
            |> List.map (fun x ->
                            match x with
                            | MonthlyAmortizationStep a -> a.Interest
                            | EarlyAmortizationStep a -> a.Interest)
            |> List.fold (fun acc el -> acc + el) 0M

        let numMonths =
            amortizationPlan.Steps
            |> List.filter (fun x ->
                                match x with
                                | MonthlyAmortizationStep _ -> true
                                | EarlyAmortizationStep _ -> false)
            |> List.length

        printfn "** Total: %M" total
        printfn "** Principal: %M" principal
        printfn "** Interest: %M" interests
        printfn "** Num months: %d, years: %0.2f" numMonths ((float)numMonths/12.0)

    let empty =
        {
            Total = 0m
            Interest = 0m
            Amount = 0m
        }

    let addAmortization a1 a2 =
        {
            Total = a1.Total + a2.Total
            Interest = a1.Interest + a2.Interest
            Amount = a1.Amount + a2.Amount
        }

    let sumAmortizations amortizations =
        amortizations |> List.fold addAmortization empty

    let toYearlyAmortizations monthlyAmortizations =
        monthlyAmortizations
        |> List.chunkBySize 12
        |> List.map sumAmortizations
