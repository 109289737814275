module Form

open Elmish
open System
open Field
open Shared

type FormData = {
    Principal: Field<decimal>
    Term: Field<int>
    Interest: Field<Shared.Percentage>
}

type MortgageData = {
    Principal: Decimal option
    Term: int option
    Interest: Percentage option
}

type Model = {
    FormData: FormData
    MortgageData: MortgageData
}

type Msg =
    | SetPrincipal of string
    | SetTerm of string
    | SetInterest of string
    | Simulate of MortgageData

let init () : Model * Cmd<Msg> =
    let formData: FormData = {
        Principal = Field.empty<decimal> ()
        Term = Field.empty<int> ()
        Interest = Field.empty<Shared.Percentage> ()
    }

    let mortgageData = {
        Principal = None
        Term = None
        Interest = None
    }
    
    let model: Model = {
        FormData = formData
        MortgageData = mortgageData
    }

    model, Cmd.none

let setPrincipal (text:string) (model: Model) : Model * Cmd<Msg> =
    let newPrincipalField = 
        match text with
        | "" -> Field.empty<decimal> ()
        | s -> Field.validate Validation.validatePrincipal s 
    
    {
        FormData = { model.FormData with Principal = newPrincipalField }
        MortgageData =  { model.MortgageData with Principal = newPrincipalField.Data }
    }, Cmd.none

let setInterest (text:string) (model: Model) : Model * Cmd<Msg> =
    let newInterestField = 
        match text with
        | "" -> Field.empty<Shared.Percentage> ()
        | s -> Field.validate Validation.validateInterest text 
    
    {
        FormData = { model.FormData with Interest = newInterestField }
        MortgageData =  { model.MortgageData with Interest = newInterestField.Data }
    }, Cmd.none

let setTerm (text:string) (model: Model) : Model * Cmd<Msg> =
    let newTermField = 
        match text with
        | "" -> Field.empty<int> ()
        | s -> Field.validate Validation.validateTerm text 
    
    {
        FormData = { model.FormData with Term = newTermField }
        MortgageData =  { model.MortgageData with Term = (newTermField.Data |> Option.bind (fun x -> Some (x*12))) }
    }, Cmd.none

let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg with
    | SetPrincipal text ->
        setPrincipal text model
    | SetTerm text ->
        setTerm text model
    | SetInterest text ->
        setInterest text model
    | Simulate _ ->
        model, Cmd.none

open Feliz
open Feliz.Bulma

let view (model: Model) (dispatch: Msg -> unit) = 
    Bulma.container [
        Bulma.field.div [
            prop.children [
                Bulma.label [
                    prop.text "Cantidad"
                ]
                Bulma.control.div [
                    prop.classes ["has-icons-left"]
                    prop.children [
                        Bulma.input.text [
                            prop.value (model.FormData.Principal.Text)
                            prop.placeholder "Ejemplo: 120000"
                            prop.onChange (fun x -> SetPrincipal x |> dispatch)
                        ]
                        (Icon.iconView "money-bill")
                    ]
                ]
                Html.p [
                    prop.classes (if (Option.isNone model.FormData.Principal.Error) then ["help"; "is-danger"; "is-hidden"] else ["help"; "is-danger"])
                    prop.text (Option.defaultValue ""  model.FormData.Principal.Error)
                ]
            ]
        ]
        Bulma.field.div [
            prop.children [
                Bulma.label [
                    prop.text "Plazo de amortización (años)"
                ]
                Bulma.control.div [
                    prop.classes ["has-icons-left"]
                    prop.children [
                        Bulma.select [ 
                            prop.onChange (fun x -> SetTerm x |> dispatch)
                            prop.children [
                                for i in 1..40 do
                                    Html.option [
                                        prop.selected (model.FormData.Term.Text = i.ToString())
                                        prop.text (i.ToString())
                                    ]
                            ]
                        ]
                        (Icon.iconView "calendar")
                    ]
                ]
            ]
        ]
        Bulma.field.div [
            prop.children [
                Bulma.label [
                    prop.text "Interés (%)"
                ]
                Bulma.control.div [
                    prop.classes ["has-icons-left"]
                    prop.children [
                        Bulma.input.text [
                            prop.value (model.FormData.Interest.Text)
                            prop.placeholder "Ejemplo: 1.2"
                            prop.onChange (fun x -> SetInterest x |> dispatch)
                        ]
                        (Icon.iconView "percent")
                    ]
                ]
                Html.p [
                    prop.classes (if (Option.isNone model.FormData.Interest.Error) then ["help"; "is-danger"; "is-hidden"] else ["help"; "is-danger"])
                    prop.text (Option.defaultValue ""  model.FormData.Interest.Error)
                ]
            ]
        ]
        Bulma.field.div [
            prop.children [
                Bulma.control.p [
                    Bulma.button.button [
                        color.isInfo
                        prop.text "Simular"
                        prop.disabled (not ((Option.isSome model.MortgageData.Principal) && (Option.isSome model.MortgageData.Term) && (Option.isSome model.MortgageData.Interest)))
                        prop.onClick (fun x -> Simulate model.MortgageData |> dispatch)
                    ]
                ]
            ]
        ]
    ]