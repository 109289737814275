module Introduction

open Feliz

let view () = 
    Html.div [
        prop.classes ["is-size-5"]
        prop.children [
            Html.p [
                prop.classes ["block"]
                prop.text "Introduce un importe, un plazo y un tipo de interés y obtendrás \
                           la cuota mensual y la tabla de amortización."
            ]
            Html.p [
                prop.classes ["block"]
                prop.text "Esta aplicación sólo realiza simulaciones de prestamos \
                    bajo el sistema de amortización francés, que es el más utilizado \
                    en España."
            ]
            Html.p [
                prop.classes ["block"]
                prop.text "El sistema de amortización francés se caracteriza porque
                           todas las cuotas son fijas, a excepción de si la hipoteca \
                           es a tipo variable o mixto, en cuyo caso cambiará en la revisión
                           del Euribor. La cuota fija está compuesta por una parte que \
                           corresponde a los intereses del préstamo y otra parte que es \
                           la cantidad que se amortiza en cada cuota."
            ]
        ]
    ]