module Warning

open Feliz
open Feliz.Bulma

let view () =
    Bulma.content [
        prop.children [
            Bulma.tag [
                prop.classes ["is-medium"; "is-warning"; "mr-1"; "block"]
                prop.text "AVISO"
            ]
            Html.p [
                prop.classes ["block"]
                prop.text "La información resultante de las simulaciones realizadas \
                           con esta aplicación es meramente orientativa, por lo cuál \
                           los datos resultantes pueden variar respecto a las solicitudes \
                           a un banco o entidad financiera."
            ]
            Html.p [
                prop.classes ["block"]
                prop.text "La única finalidad de esta aplicación es orientar y dar una \
                           estimación sobre los cálculos de amortización de una hipoteca \
                           de tipo fijo bajo el sistema de amortización francés."
            ]
            Html.p [
                prop.classes ["block"]
                prop.text "En ningún caso los resultados de las simulaciones pueden considerarse \
                           una oferta contractual y no suponen ninguna obligación por parte de \
                           simula-tu-hipoteca."
            ]
        ]
    ]