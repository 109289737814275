module Validation

open Shared.TryParser

module Decimal = 

    let fromString (text:string) = 
        match text with
        | Decimal d -> Ok d
        | _ -> Error "Número no válido"

    let validatePositive (number:decimal) =
        if number < 0M then
            Error "El número no puede ser negativo"
        else
            Ok number

    let validateLessThan (limit:decimal) (number:decimal) =
        if number > limit then
            Error "El número supera el límite"
        else
            Ok number

module Int =
    let fromString (text:string) = 
        match text with
        | Int d -> Ok d
        | _ -> Error "Número no válido"

    let validatePositive (number:int) =
        if number < 0 then
            Error "El número no puede ser negativo"
        else
            Ok number

    let validateLessThan (limit:int) (number:int) =
        if number > limit then
            Error "El número supera el límite"
        else
            Ok number

let validatePrincipal (text:string) : Result<decimal, string> =
    text |> Decimal.fromString
         |> Result.bind Decimal.validatePositive
         |> Result.bind (Decimal.validateLessThan 1000000M)

let validateInterest (text:string): Result<Shared.Percentage, string> =
    text |> Decimal.fromString
         |> Result.bind Shared.Percentage.tryCreateFromInt

let validateTerm (text:string) : Result<int, string> =
    text |> Int.fromString
         |> Result.bind Int.validatePositive
         |> Result.bind (Int.validateLessThan 50)
        