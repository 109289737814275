module Table

open Elmish
open Shared

type TableType =
    | Monthly
    | Yearly

type Model = {
    Mortgage: FullMortgage option
    TableType: TableType
}

type Msg =
    | SetMortgage
    | SetTableType of TableType

let init () : Model * Cmd<Msg> = 
    let model = {
        Mortgage = None
        TableType = Monthly
    }

    model, Cmd.none

let unfoldPrincipal (principal:decimal, amortizations: Amortization list) =
    match amortizations with
    | [] -> None
    | first :: remaining ->
        let pendingAfter = principal - first.Amount
        Some ((principal, first), (pendingAfter, remaining))

let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg with
    | SetTableType tableType ->
        { model with TableType = tableType }, Cmd.none
    | SetMortgage -> model, Cmd.none

open Feliz
open Feliz.Bulma

let view (model: Model) (dispatch: Msg -> unit) = 
    Html.div [
        Html.h2 [
            prop.classes ["title"; "is-4"]
            prop.text "Tabla de amortización"
        ]
        Bulma.tabs [
            Html.ul [
                Bulma.tab [
                    if model.TableType = Monthly then (tab.isActive)
                    prop.children [
                        Html.a [
                            prop.text "Mensual"
                            prop.onClick (fun x -> SetTableType Monthly |> dispatch)
                        ]
                    ]
                ]
                Bulma.tab [
                    if model.TableType = Yearly then (tab.isActive)
                    prop.children [
                        Html.a [
                            prop.text "Anual"
                            prop.onClick (fun x -> SetTableType Yearly |> dispatch)
                        ]
                    ]
                ]
            ]
        ]
        Bulma.table [
            table.isFullWidth
            table.isStriped
            table.isHoverable
            prop.children [
                Html.thead [
                    Html.tableRow [
                        Html.th [
                            prop.classes ["has-text-right"]
                            prop.text (if model.TableType = Monthly then "Mes" else "Año")
                        ]
                        Html.th [
                            prop.classes ["has-text-right"]
                            prop.text "Capital pendiente"
                        ]
                        Html.th [
                            prop.classes ["has-text-right"]
                            prop.text "Cuota"
                        ]
                        Html.th [
                            prop.classes ["has-text-right"]
                            prop.text "Intereses"
                        ]
                        Html.th [
                            prop.classes ["has-text-right"]
                            prop.text "Capital amortizado"
                        ]
                    ]
                ]
                if Option.isSome model.Mortgage then
                    Html.tbody [
                            let amortizations =
                                match model.TableType with
                                | Monthly -> model.Mortgage.Value.Amortization
                                | Yearly -> Amortization.toYearlyAmortizations model.Mortgage.Value.Amortization
                            let extendedAmortizations = (model.Mortgage.Value.Definition.Principal, amortizations) |> List.unfold unfoldPrincipal

                        for index, (pending, amortization) in extendedAmortizations |> List.indexed do
                            Html.tableRow [
                                Html.th [
                                    prop.classes ["has-text-right"]
                                    prop.text ((index + 1).ToString())
                                ]
                                Html.td [
                                    prop.classes ["has-text-right"]
                                    prop.text (pending |> Formatting.formatAmountWithDecimals)
                                ]
                                Html.td [
                                    prop.classes ["has-text-right"]
                                    prop.text (amortization.Total |> Formatting.formatAmountWithDecimals)
                                ]
                                Html.td [
                                    prop.classes ["has-text-right"]
                                    prop.text (amortization.Interest |> Formatting.formatAmountWithDecimals)
                                ]
                                Html.td [
                                    prop.classes ["has-text-right"]
                                    prop.text (amortization.Amount |> Formatting.formatAmountWithDecimals)
                                ]
                            ]
                    ]
            ]
        ]
    ]
