module Summary

open Elmish
open Shared

type Model = {
    Mortgage: FullMortgage option
}

type Msg =
    | SetMortgage of FullMortgage

let init () : Model * Cmd<Msg> =
    {
        Mortgage = None
    }, Cmd.none

let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg with
    | SetMortgage mortgage ->
        model, Cmd.none

open Feliz
open Feliz.Bulma

let view (model: Model) (dispatch: Msg -> unit) = 
    let principal = 
        match model.Mortgage with
        | Some m -> m.Definition.Principal |> Formatting.formatAmountWithDecimals
        | None -> ""

    let monthlyPayment = 
        match model.Mortgage with
        | Some m -> m.Definition.MonthlyPayment |> Formatting.formatAmountWithDecimals
        | None -> "" 

    let totalAmortization = 
        match model.Mortgage with
        | Some m -> m.Amortization |> Amortization.sumAmortizations
        | None -> Amortization.empty

    let totalInterests = totalAmortization.Interest |> Formatting.formatAmountWithDecimals

    let total = totalAmortization.Total |> Formatting.formatAmountWithDecimals

    Bulma.columns [
        Bulma.column [
            prop.children [
                Html.div [
                    prop.className ["has-text-centered"; "is-size-4"]
                    prop.text "Cuota mensual"
                ]
                Html.div [
                    prop.className ["has-text-centered"; "is-size-2"; "has-text-weight-bold"]
                    prop.text (sprintf "%s €" monthlyPayment)
                ]
            ]
        ]
        Bulma.column [
            prop.children [
                Bulma.table [
                    table.isFullWidth
                    prop.children [
                        Html.tableBody [
                            Html.tableRow [
                                Html.th "Importe"
                                Html.td [ 
                                        prop.className "has-text-right"
                                        prop.text (sprintf "%s €" principal)
                                    ]
                            ]
                            Html.tableRow [
                                Html.th "Intereses"
                                Html.td [ 
                                        prop.className "has-text-right"
                                        prop.text  (sprintf "%s €" totalInterests)
                                    ]
                            ]
                            Html.tableRow [
                                Html.th "Total"
                                Html.td [ 
                                        prop.className "has-text-right"
                                        prop.text (sprintf "%s €" total)
                                    ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]
