module NotFound

open Elmish

type Model = Undefined

type Msg = Undefined

let init () = Model.Undefined, Cmd.none

open Feliz
open Feliz.Bulma

let view (model: Model) (dispatch: Msg -> unit) =
    Html.h1 "Not found"