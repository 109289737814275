module Field

type Field<'t> = {
    Text: string
    Error: string option
    Data: 't option
}

let validate validator text = 
    let result = validator text

    let data, error = 
        match result with 
        | Ok data -> Some data, None
        | Error error -> None, Some error

    {
        Text = text
        Error = error
        Data = data
    }


let empty<'t> () =
    {
        Text = ""
        Data = Option<'t>.None
        Error = None
    }

let getError field = 
    match field.Error with
    | Some error -> error
    | None -> ""
