module Icon

open Feliz
open Feliz.Bulma

let iconView (name: string) =
    Bulma.icon [
        icon.isLarge
        icon.isLeft
        prop.children [
            Html.i [
                prop.classes ["fas";("fa-" + name )]
            ]
        ]
    ]

let labelIconView (name: string) (text: string) =
    Html.span [
        prop.className "icon-text"
        prop.children [
            (iconView name)
            Html.span [
                prop.text text
            ]
        ]
    ]