namespace Shared

open System

module AnotherModule = 
    let thi () = ()

[<CompilationRepresentation(CompilationRepresentationFlags.ModuleSuffix)>]
module FixedRateMortgage =
    let calculateMonthlyPayment principal interest (term:uint) = 
        let i = Percentage.toFloat interest / 12.0

        let numerator = decimal ((1.0 + i)**(float term))
        let denominator = decimal ((1.0 + i)**(float term) - 1.0)

        let monthlyPayment = principal * (decimal i) * (numerator / denominator)

        Math.Round(monthlyPayment, 2)

    let createWithMonthlyPayment principal interest monthlyPayment = 
        {
            Principal = principal
            Interest = interest
            MonthlyPayment = monthlyPayment
        }

    let createWithTerm principal interest term =
        let monthlyPayment = calculateMonthlyPayment principal interest term

        createWithMonthlyPayment principal interest monthlyPayment
    
    let tryCreateWithTerm principal (interest:float) term = result {
        let! validatedPrincipal = 
            match principal with
            | x when x > 0M -> Ok(x)
            | x -> Error "Principal amount must be greater than 0"
        let! validatedInterest = Percentage.tryCreate ((decimal)interest)
        
        return createWithTerm validatedPrincipal validatedInterest term
    }

    let calculateMonthlyInterest principal interest = 
        let interestValue = Percentage.value interest

        let i = interestValue / 12M

        Math.Round(i * principal, 2)

    let calculateMonthlyMortgage principal interest monthlyPayment =
        let monthlyInterest = calculateMonthlyInterest principal interest

        let amount =
            match (monthlyPayment - monthlyInterest) with
            | x when principal < x -> principal
            | x -> x

        {
            Total = amount + monthlyInterest
            Amount = amount
            Interest = monthlyInterest
        }

    let unfoldAmortizationMonth (principal, interest, monthlyPayment) =
        if principal <= 0M then
            None
        else
            let monthlyMortgage = calculateMonthlyMortgage principal interest monthlyPayment
            let pendingPrincipal = principal - monthlyMortgage.Amount

            Some (monthlyMortgage, (pendingPrincipal, interest, monthlyPayment))

    let calculateAmortizationSchedule mortgage =
        (mortgage.Principal, mortgage.Interest, mortgage.MonthlyPayment)
        |> List.unfold unfoldAmortizationMonth

    let getPendingPrincipalBeforeMonth mortgage (month:uint) =
        let schedule = calculateAmortizationSchedule mortgage
        let amortizedAmount = 
            schedule
            |> List.take ((int)month)
            |> List.fold (fun x el -> x + el.Amount) 0M

        mortgage.Principal - amortizedAmount

    let getTerm mortgage = 
        let result = 
            mortgage 
            |> calculateAmortizationSchedule
            |> List.length

        (uint)result

    let printNextMonthPayment pending (monthlyMortgage:Amortization) =
        let nextPending = pending - monthlyMortgage.Amount
        printf "Pending principal before: %M" pending
        printf ", Amortization: %M" monthlyMortgage.Amount
        printf ", Interest: %M" monthlyMortgage.Interest
        printfn ", Pending principal after: %M" nextPending

        nextPending

    let printSchedule mortgage =
        calculateAmortizationSchedule mortgage
            |> List.fold printNextMonthPayment mortgage.Principal
            |> ignore

    let print mortgage = 
        printfn ""
        printfn "Fixed rate mortgage"
        printfn "-------------------"
        printfn " - Principal: %M " mortgage.Principal
        printfn " - Interest: %0.3f" (Percentage.value mortgage.Interest)
        printfn " - Monthly payment: %M" mortgage.MonthlyPayment
        let amortizationSchedule = calculateAmortizationSchedule mortgage
        printfn " - Num quotas: %d, years: %0.2f" amortizationSchedule.Length ((float)amortizationSchedule.Length / 12.0)
        printfn " - Total interest: %M" (amortizationSchedule |> List.map (fun x -> x.Interest) |> List.sum)
        printfn " - Total: %M" (amortizationSchedule |> List.map (fun x -> x.Total) |> List.sum)
        printfn " - Principal: %M" (amortizationSchedule |> List.map (fun x -> x.Amount) |> List.sum)
        printSchedule mortgage
